import BlockHeader from '@component/BlockHeader';
// import DownloadButton from '@component/DownloadButton';
import Flex from '@componentPrimitives/Flex';
import { BlockContainer } from '@components/Blocks';
import DownloadItemBlock from '@components/Downloads/DownloadItemBlock';
import { createBlock } from '@lib/features/blocks/blocks';

const DownloadsBlock = createBlock<'blocks_resources_BlockType'>(
  ({ heading, content, children }) => (
    <BlockContainer maxWidth="md">
      <BlockHeader {...{ heading, content }} />
      <Flex spacing="gutter">
        {children?.map((v, i) => (
          <DownloadItemBlock key={i} {...v} />
        ))}
      </Flex>
    </BlockContainer>
  )
);

export default DownloadsBlock;
