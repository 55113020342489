import DownloadButton from '@components/DownloadButton';
import { createBlock } from '@lib/features/blocks/blocks';

const DownloadItemBlock = createBlock<'blocks_resourceItem_BlockType'>(
  ({ content, heading, file }) =>
    file ? (
      <DownloadButton summary={content ?? undefined} title={heading ?? undefined} file={file} />
    ) : null
);

export default DownloadItemBlock;
