import Icon from '@component/Icon';
import Txt from '@primitive/Txt';
import Box from '@primitive/Box';
import { BtnProps } from '@componentPrimitives/Btn';
import { FileAsset } from '@lib/features/common';
// import type {DownloadCtaVariants} from './DownloadCta.css'
import { makeNonNullableArray } from '@liquorice/allsorts-craftcms-nextjs';
import { Stack } from '@primitives';
import * as style from './DownloadButton.css';
import Flex from '@componentPrimitives/Flex';
// import { colorVar } from '@theme/index';

export type DownloadButtonProps = BtnProps & {
  title?: string;
  summary?: string;
  file?: Partial<FileAsset>;
};

const DownloadButton = ({
  file = {},
  title: customTitle,
  summary: customSummary,
}: DownloadButtonProps) => {
  const { url: href, extension, sizeHuman } = file;

  const summary = customSummary ?? '';
  // const summary = customSummary ?? file.summary;
  const title = customTitle ?? file.title;

  const ext = extension ? extension.toUpperCase() : null;
  const size = sizeHuman ?? null;
  const meta = makeNonNullableArray([ext, size]);

  const metaText = `(${meta.join(', ')})`.toUpperCase();
  const ariaLabel = `${title} ${metaText}`;

  return !href ? (
    <></>
  ) : (
    <Box
      as="a"
      className={style.root}
      download={'download'}
      aria-label={ariaLabel}
      href={href}
      target="_blank">
      <Icon className={style.icon} name="download" sx={{}} />
      <Stack component="span" className={style.inner}>
        <Txt bold variant="bodyAlt" as="span" autoMargin className={style.heading}>
          {title}
        </Txt>
        <Txt html variant="small">
          {summary}
        </Txt>
        <Flex direction="row" justifyContent="between">
          <Txt as="span">{ext}</Txt>
          <Txt as="span">{size}</Txt>
        </Flex>
      </Stack>
    </Box>
  );
};

export default DownloadButton;
